@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

body {
  background-image: url("../Images/background2.jpg");
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
}

:root {
  --mainColor: rgb(61, 41, 41);
  --mainColorLight: rgb(124, 107, 107);
  --textColor: white;
  --secondaryColor: rgb(214, 161, 27);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  z-index: 2;
}

nav ul li a {
  margin: 0 3rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 18px;
}

nav ul li a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

nav {
  height: 70px;
}

nav ul {
  list-style: none;
}

nav ul li {
  position: relative;
  height: 70px;
  float: left;
  display: flex;
  align-items: center;
}

nav ul li:hover .menu {
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  top: 60px;
  max-height: 600px;
  transition: max-height 0.25s ease-in;
  font-size: 20px;
  width: 100%;
  background-color: var(--mainColorLight);
  left: 0;
}

.closed {
  background-color: aqua;
  z-index: -100;
  position: fixed;
  max-height: 0;
  overflow: hidden;
}

.menu {
  z-index: -100;
  position: fixed;
  max-height: 0;
  overflow: hidden;
}

.menu div {
  display: flex;
  flex-direction: column;
}

.menu h3 {
  padding-bottom: 5px;
}

.menu a {
  margin: 3px 0;
}

@media only screen and (max-width: 1054px) {
  header {
    height: 100px;
  }

  header h1 {
    font-size: 60px;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    right: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    z-index: 2;
  }

  nav ul li a {
    margin: 0 1rem;
    font-size: 30px;
  }

  .menu p {
    font-size: 20px;
  }

  .closed {
    display: none;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav ul li:hover > .menu {
    top: 165px;
  }
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

.center {
  display: flex;
  justify-content: center;
}
