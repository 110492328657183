.cases {
  padding: 4em 6em;
}

.cases p {
  font-size: 20px;
  padding-bottom: 10px;
}

.caseMain {
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
}

.caseMain img {
  width: 450px;
  height: auto;
}

@media only screen and (max-width: 1054px) {
}
