.book {
  padding: 2em 6em;
}

.book p {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 40%;
}

.book button {
  margin-top: 1em;
  width: 160px;
  height: 60px;
  font-size: 22px;
}

.bookDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.bookDisplay img {
  width: 20%;
  height: auto;
}
