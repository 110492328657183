.intro {
  display: grid;
  justify-items: center;
  padding: 0 6em;
}

.intro p {
  padding-right: 20em;
}

.cover {
  max-width: 500px;
  padding-top: 6em;
  padding-left: 6em;
  padding-bottom: 10em;
}

.cover p {
  /* margin-top: 1em; */
  font-size: 22px;
}

.cover button {
  margin-top: 1em;
  width: 160px;
  height: 60px;
  font-size: 22px;
}

.showCase {
  width: 100%;
  padding: 2em 6em;
  background-color: rgb(208, 197, 197, 0.8);
}

.inLine {
  display: flex;
  gap: 20px;
  align-items: center;
}

.inLine a {
  font-size: 17px;
  color: rgb(61, 41, 41);
}

.inLine h2 {
  color: rgb(40, 26, 26);
}

.images {
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  width: auto;
}

.images img {
  width: 300px;
  height: 250px;
  box-shadow: 2px 2px 4px rgb(123, 99, 99);
  border-radius: 4px;
}

.coverImage {
  position: absolute;
  display: grid;
  right: 0;
  top: 80px;
  z-index: -2;
}

.coverImage img {
  width: 700px;
}

.coverImage_item:first-of-type {
  grid-column: 1 / span 4;
  grid-row: 1 / span 4;
}

.coverImage_item:last-of-type {
  grid-column: 4 / span 5;
  grid-row: 2 / span 5;
  z-index: -1;
}

button {
  background-color: rgb(83, 57, 57);
  border: none;
  color: white;
  border-radius: 4px;
}

button:hover {
  background-color: rgb(188, 171, 171);
  color: rgb(61, 41, 41);
}

.cover span {
  display: inline-block;
  color: rgb(67, 16, 0);
  animation: waviy 2s infinite;
  animation-delay: calc(0.1s * var(--i));
  font-size: 50px;
  font-weight: bold;
  text-shadow: 0 0 3vw rgb(221, 143, 143);
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

@media only screen and (max-width: 1280px) {
  .coverImage_item:first-of-type {
    grid-column: 3 / span 3;
    grid-row: 1 / span 2;
  }
  .coverImage_item:last-of-type {
    grid-column: 4 / span 4;
    grid-row: 2 / span 5;
    z-index: -1;
  }
}

@media only screen and (max-width: 1024px) {
  .cover {
    padding-top: 20em;
    max-width: 600px;
  }

  .cover p {
    background-color: rgb(188, 171, 171, 0.8);
  }

  .coverImage img {
    padding-top: 2em;
    width: 500px;
  }

  .coverImage_item:first-of-type {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }

  .coverImage_item:last-of-type {
    grid-column: 2 / span 3;
    grid-row: 1 / span 3;
  }

  .intro p {
    padding-right: 0;
  }

  .inLine h2 {
    font-size: 40px;
  }
}
