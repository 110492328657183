.design {
  padding: 4em 6em;
}

.design .inline {
  display: flex;
  gap: 20px;
}

.design .inline img {
  width: 500px;
}

.design img {
  box-shadow: 2px 2px 4px rgb(104, 82, 82);
  border-radius: 4px;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .design .inline {
    display: flex;
    flex-direction: column;
  }
}
